<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>所有工单</h1>
      </el-header>
      <el-main class="scrollable-main">
        <!-- 添加筛选框 -->
        <el-form :inline="true" class="filter-form">
          <!-- 时间筛选框 -->
          <el-form-item label="时间">
            <el-date-picker
                v-model="startDate"
                type="date"
                format="yyyy-MM-dd"
                placeholder="选择起始日期"
                @change="handleDateRangeChange"
            ></el-date-picker>
            <el-date-picker
                v-model="endDate"
                type="date"
                format="yyyy-MM-dd"
                placeholder="选择截止日期"
                @change="handleDateRangeChange"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="选择车间">
            <el-select v-model="selectedLine" clearable placeholder="请选择车间" @change="handleLineChange">
              <el-option v-for="line in lines" :key="line.id" :label="line.name" :value="line.id"></el-option>
            </el-select>
          </el-form-item>

          <!-- 状态筛选框 -->
          <el-form-item label="状态">
            <el-select v-model="selectedState" clearable placeholder="请选择状态" @change="handleStateChange">
              <el-option label="全部" value=""></el-option>
              <el-option label="已接单" value="已接单"></el-option>
              <el-option label="维修中" value="维修中"></el-option>
              <el-option label="暂停中" value="暂停中"></el-option>
              <el-option label="作业结束" value="作业结束"></el-option>
              <el-option label="工单关闭" value="工单关闭"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template v-if="records.length === 0">
          <div class="no-data">暂无数据</div>
        </template>
        <template v-else>
          <el-card v-for="item in filteredRecords" :key="item.id" style="margin-bottom: 20px;">
            <div slot="header">
              <div>工单号：{{ item.reconum }}
                <el-tag :type="getTagType(item.state)" size="medium" style="margin-left: 20px;">{{ item.state }}</el-tag>
              </div>
            </div>
            <div>
              <div><span>接单人:{{ getHostUser(item.reconum) }}</span></div>
              <div><span v-if="getHelpUser(item.reconum)">助手:{{ getHelpUser(item.reconum) }}</span></div>
              <el-divider></el-divider>
              <div><span>工单类型:{{ item.type }}</span></div>
              <div><span>故障设备:{{ equipments.find(v => v.id === item.equipmentId) ? equipments.find(v => v.id === item.equipmentId).name : '' }}</span></div>
              <div><span>故障描述:{{ item.description }}</span></div>
              <div><span>工单总工时：{{ formatTime(item.workHour) }}</span></div>
            </div>
            <el-divider></el-divider>
            <el-row type="flex" justify="center" style="margin-top: 10px;">
              <el-col class="text-center">
                <el-button @click="goToDetail(item.id)">查看</el-button>
              </el-col>
            </el-row>
          </el-card>
        </template>
      </el-main>

      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "MRepairView",
  data() {
    return {
      form: {},
      equipments: [],
      lines: [],
      users: [],
      records: [],
      rmByreconum: [],
      startDate : "",
      endDate : "",
      selectedState: null,
      selectedLine: null,
      timer: "",
      content: "",
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    };
  },
  created() {
    this.load();
  },
  mounted() {
    this.timer = setInterval(this.fetchRecords, 60000); // Fetch records every minute
  },
  computed: {
    faultTypeOptions() {
      return this.failureType[this.form.type] || [];
    },
    filteredRecords() {
      return this.getFilteredRecords();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 获取数据
    load() {
      this.request.get("/failureRecord").then(res => {
        this.records = res.data;
      });
      this.request.get("/recordMaintainer").then(res => {
        this.rmByreconum = res.data;
      });
      this.request.get("/equipment").then(res => {
        this.equipments = res.data;
      });
      this.request.get("/line").then(res => {
        this.lines = res.data;
      });
      this.request.get("/user").then(res => {
        this.users = res.data;
      });
    },
    loadComponent(){
      this.request.get("/component").then(res => {
        this.components = res.data;
      });
    },
    save() {
      const recordTime = new Date().toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai', // 设置为中国标准时间
        hour12: false, // 24小时制
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '');

      // 将时间格式化为 "YYYY-MM-DD HH:MM:SS"
      const formattedRecordTime = recordTime.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3').replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2:$3');

      const formData = {
        sum: this.form.sum,
        compnum: this.form.compnum,
        recordId: this.form.id,
        price: this.form.price * this.form.sum,
        takeTime: formattedRecordTime,
      };
      if(this.form.compnum != null && this.form.sum != null){
        this.request.post("/recordComponent", formData).then(res => {
          if (res.code === '200') {
            this.$message.success("保存成功")
            this.request.post("/component/updateSum", formData)
            this.dialogFormVisible = false
            this.loadComponent();
          } else {
            this.$message.error("保存失败")
          }
        })
      }else{
        this.$message.warning("备件号或数量为空")
      }
    },
    goToHome() {
      this.$router.push('/mobile');
    },
    open(recordId) {
      this.dialogFormVisible6 = true
      this.form={
        id: recordId,
      }
    },
    formatTime(hours) {
      // 将小时数格式化为天时分秒的字符串显示
      const remainingHours = Math.floor(hours % 24);
      const minutes = Math.floor((hours * 60) % 60);
      return `${remainingHours}小时${minutes}分钟`;
    },
    pauseResumeTimer(item) {
      const userState = this.getUserState(item.reconum)
      console.log("pauseResumeTimer"+item.id)
      if (item) {
        if (userState === '维修中') {
          this.request.post("/failureRecord/pause/" + item.id + "/" + this.user.id).then(res => {
            if (res.code === '200') {
              //item.state = '暂停中';
              this.request.post("/repairReport/pause/"+item.reportId)
              this.load();
              this.$message.success("暂停成功");
            } else {
              this.$message.error("暂停失败");
            }
          });
        }
        if((userState === '暂停中' || userState === '已接单')){
          this.request.post("/failureRecord/continuerecord/" + item.id + "/" + this.user.id).then(res => {
            if (res.code === '200') {
              //item.state = '维修中';
              this.request.post("/repairReport/start/"+item.reportId)
              this.load();
              this.$message.success("继续成功");
            } else {
              this.$message.error("继续失败");
            }
          });
        }
      }
    },
    handleDateRangeChange() {
      this.filteredRecords = this.getFilteredRecords();
    },
    handleStateChange() {
      this.filteredRecords = this.getFilteredRecords();
    },
    handleLineChange() {
      this.filteredRecords = this.getFilteredRecords();
    },
    getFilteredRecords() {
      let filtered = this.records;

      // 过滤日期范围
      if (this.startDate) {
        filtered = filtered.filter(item => new Date(item.startTime) >= new Date(this.startDate));
      }
      if (this.endDate) {
        filtered = filtered.filter(item => new Date(item.startTime) <= new Date(this.endDate));
      }
      if (this.selectedLine) {
        filtered = filtered.filter(item => item.lineId === this.selectedLine);
      }
      // 过滤状态
      if (this.selectedState) {
        filtered = filtered.filter(item => item.state === this.selectedState);
      }

      return filtered;
    },
    goToDetail(recordId) {
      console.log('Clicked item id:', recordId);
      this.$router.push({ name: 'MRecordDetail1', params: { id: recordId } });
    },
    getTagType(state) {
      // 根据状态返回不同的标签类型（颜色）
      switch (state) {
        case '审批不通过':
          return 'danger';
        case '工单关闭':
          return 'success';
        case '作业结束':
          return 'info';
        case '维修中':
          return '';
        case '暂停中':
          return 'warning';
      }
    },
    download(equipmentId) {
      const equipment = this.equipments.find(item => item.id === equipmentId);
      const url = equipment ? equipment.avatarUrl : '';

      if (url) {
        window.open(url);
      } else {
        this.$message.warning("无SOP文件");
      }
    },
    getUserState(reconum){
      const filterdata = this.rmByreconum.filter(item => item.reconum === reconum)
      const state = filterdata.find(v => v.userId === this.user.id).state
      return state;
    },
    getHostUser(reconum){
      const filterdata = this.rmByreconum.filter(item => item.reconum === reconum)
      const userName = filterdata.find(v => v.role === "接单人") ? filterdata.find(v => v.role === "接单人").userName : ''
      const state = filterdata.find(v => v.role === "接单人") ? filterdata.find(v => v.role === "接单人").state : ''
      return userName + "("+ state +")";
    },
    getHelpUser(reconum){
      const filterdata = this.rmByreconum.filter(item => item.reconum === reconum)
      const recomt = filterdata.filter(item => item.role === "助手");
      return recomt.map(item => {
        const userName = item ? item.userName : ''
        const state = item ? item.state : ''
        const alldata = userName + "("+ state +")";
        return  alldata;
      }).join(', ');
    },
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}
.scrollable-main {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>